<template>
  <div>
    <el-dialog title="添加" :visible.sync="dialogVisible" width="60%">
      <el-row>
        <el-form label-width="20px">
          <el-col :span="10">
            <el-form-item>
              <el-select
                v-model="form.brand"
                placeholder="请选择品牌"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in brandList"
                  :key="item.brand"
                  :label="item.brand"
                  :value="item.brand"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item>
              <el-select
                v-model="form.signalTypeName"
                placeholder="请选择告警类型"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in signalTypeList"
                  :key="item.signalTypeName"
                  :label="item.signalTypeName"
                  :value="item.signalTypeName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="4" style="display: flex; justify-content: end;">
            <el-button type="primary" @click="getlist()">搜索</el-button>
          </el-col>
        </el-form>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-table
            :data="tableData"
            border
            fit
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :row-key="
              row => {
                row.id;
              }
            "
            height="530px"
            ref="multipleTable"
            class="my-table-class"
          >
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column
              min-width="140px"
              label="告警信息"
              align="center"
              prop="signalName"
            ></el-table-column>
            <el-table-column
              min-width="140px"
              label="告警类型"
              align="center"
              prop="signalTypeName"
            ></el-table-column>
          </el-table>
          <div style="margin-top: 10px; display: flex; justify-content: end">
            共{{ tatol }}条
          </div>
        </el-col>
        <el-col :span="12" class="selectedUl pr10 bbox">
          <div style="padding: 10px;">
            <p class="wp100 bbox flex justify-between textblue">
              <span>已选择({{ chooseTatol }})</span>
              <!-- <span class="pointer" @click="emptyAuthority"><i class="el-icon-delete"></i> 清空</span> -->
            </p>
            <div
              class="selectedUl-box"
              style="height: 500px; overflow: scroll;"
            >
              <ul>
                <li
                  class="clearfix"
                  v-for="(item, i) in multipleSelection"
                  :key="item.powerStationCode"
                >
                  <span class="rightOrderBox1">{{ item.signalName }}</span>
                  <i
                    class="el-icon-close floatRight iconOrganization iconblue fw700 f15"
                    @click="delAuthority(i, item)"
                  ></i>
                </li>
              </ul>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" style="margin-top: 10px">
        <el-button type="primary" @click="toAdd">添加</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      multipleSelection: [],
      row: {},
      form: {},
      brandList: [],
      signalTypeList: [],
      echoList: [],
      tatol: "0",
      chooseTatol: "22",
    };
  },
  methods: {
    init(row) {
      this.dialogVisible = true;
      this.row = row;
      this.form = {
        brand: "锦浪",
        signalTypeName: "告知信息"
      };
      this.togetSelect();
      this.getBrandList();
      this.getTypeList();
      this.getTatol();
    },
    getlist() {
      this.getTableData();
      this.queryStationList();
      this.getTatol();
    },
    // 初始化时调用
    async togetSelect() {
      await this.getTableData();
      await this.queryStationList();
    },
    // 鼠标单击事件
    handleRowClick(val) {
      let index = this.multipleSelection.findIndex(item => {
        return item.id == val.id;
      });
      if (index > -1) {
        this.multipleSelection.splice(index, 1);
      } else {
        this.multipleSelection.push(val);
      }
    },
    // 删除选中的数据
    delAuthority(index, currRow) {
      this.multipleSelection.splice(index, 1);
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.multipleTable.toggleRowSelection(currRow, false);
        });
      });
    },
    // 获取数据列表
    async getTableData() {
      let data = {
        userCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
        ...this.form
      };
      let res = await this.$http.post(
        "deviceAlarmDefinition/queryAlarmList",
        data
      );
      if (res.data.code == 200) {
        this.tableData = res.data.data;
      }
    },
    async toAdd() {
      let data = {
        roleCode: this.row.roleCode,
        alarmList: this.multipleSelection,
        ...this.form
      };
      let res = await this.$http.post("roleDeviceAlarm/bingRoleAndAlarm", data);
      if (res.data.code == 200) {
        this.$emit("roleBindAdd");
        this.dialogVisible = false;
      }
    },
    // 获取品牌列表
    async getBrandList() {
      let data = {};
      let res = await this.$http.post(
        "deviceAlarmDefinition/queryBrandList",
        data
      );
      if (res.data.code == 200) {
        this.brandList = res.data.data;
      }
    },
    // 获取告警类型
    async getTypeList() {
      let data = {};
      let res = await this.$http.post(
        "deviceAlarmDefinition/queryTypeList",
        data
      );
      if (res.data.code == 200) {
        this.signalTypeList = res.data.data;
      }
    },
    // 获取总和
    async getTatol() {
      let res = await this.$http.post(
        "deviceAlarmDefinition/alarmNum",
        this.form
      );
      if (res.data.code == 200) {
        this.tatol = res.data.data.listCount;
      }
    },
    // 回显
    async queryStationList() {
      let data = {
        roleCode: this.row.roleCode,
        ...this.form
      };
      let res = await this.$http.post("roleDeviceAlarm/queryList", data);
      if (res.data.code == 200) {
        let echoList = res.data.data;
        //保证有值再进行比对，避免报错
        if (echoList && this.tableData.length != 0) {
          //遍历table表格数据与要回显的数组比对，匹配成功的设置为选中状态
          this.$nextTick(() => {
            setTimeout(() => {
              this.multipleSelection = [];
              this.tableData.forEach(row => {
                echoList.forEach(item => {
                  if (row.id == item.alarmId) {
                    this.multipleSelection.push(row);
                    this.$refs.multipleTable.toggleRowSelection(row, true);
                    //判断唯一标识，若是id一样，则设置该行选中状态为true
                    // this.multipleSelection.push(row)
                  } else {
                  }
                });
              });
              this.chooseTatol = echoList.length;
            },300);
          });
        }
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.chooseTatol = this.multipleSelection.length;
    }
  }
};
</script>
<style lang="scss" scoped></style>
