<template>
  <div class="managingPeople hp100 bbox bginit" v-loading="loading">
    <!-- 卡片视图 -->
    <!-- <el-card class="bginit" style="border: none"> -->
    <!-- 搜索-->
    <div class="top">
      <div>
        <el-button plain class="add-column-btn bbox" @click="gotoDetail('')" type="primary">
          <i class="el-icon-plus fw900 f16"></i> 新增</el-button>
      </div>
      <div>
        <div class="flex align-center justify-end header-search-box">
          <el-input placeholder="查询条件" clearable v-model="searchInput" class="header-search-input ml10">
            <el-button slot="append" icon="el-icon-search" class="searchBtn" @click="search()"></el-button>
          </el-input>
        </div>
      </div>
    </div>
    <!-- 用户列表区域 -->
    <div class="abc" style="margin-top: 20px">
      <el-row style="width: 100%" class="table-class">
        <el-table :data="tableData" border style="width: 100%" height="74vh" @sort-change="sortChange">
          <el-table-column label="序号" align="center" type="index" >
          </el-table-column>
          <el-table-column label="角色名称" width="140" align="center" prop="角色名称">
            <template slot-scope="scope">
              <span>{{ scope.row.roleName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="角色描述" align="center" prop="desInfo">
          </el-table-column>
          <el-table-column label="创建时间" min-width="160" width="180px" align="center" prop="">
            <template slot-scope="scope">
              <span>{{ scope.row.createDt }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300px">
            <template slot-scope="scope">
              <span style="cursor: pointer; color: #fcfcfc" @click="choseMenu(scope.row)">分配菜单</span>
              <span style="cursor: pointer; color: #fcfcfc; padding: 0 6px" @click="getInfo(scope.row)">分配人员</span>
              <span style="cursor: pointer; color: #fcfcfc; padding: 0 6px" @click="openAlarmBind(scope.row)">告警绑定</span>
              <span style="color: #f59a23; cursor: pointer" @click="editRole(scope.row)">
                编辑
              </span>
              <span style="color: #ff7586; cursor: pointer; padding: 0 6px" v-if="scope.row.roleName != '系统默认角色'"
                @click="del(scope.row.roleCode)">
                删除
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-sizes="[20, 50, 100, 200]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" class="pagination-style" :total="total"></el-pagination>
      </el-row>
    </div>
    <!-- </el-card> -->
    <el-dialog :title="roleLabelType == 'add' ? '新增角色' : '编辑角色'" :visible.sync="dialogVisible" width="30%">
      <el-form label-width="90px">
        <el-form-item label="角色名称:">
          <el-input v-model="roleForm.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="角色描述:">
          <el-input v-model="roleForm.desInfo" type="textarea" placeholder="请输入角色名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="`分配菜单___${chosedRow.roleName}`" :visible.sync="dialogVisible_new" width="820px"
      custom-class="menuClass">
      <div style="display: flex">
        <div class="le" style="width: 50%">
          <div v-loading="treeLoading" style="height: 65vh; overflow: auto" class="treeBody">
            <div class="titletext">PC端</div>
            <el-checkbox v-model="checkedPc">全选</el-checkbox>
            <el-tree ref="tree" default-expand-all :data="treeData" @check="pcCheck" show-checkbox node-key="menuCode"
              :props="defaultProps">
            </el-tree>
          </div>
        </div>
        <div class="ri" style="width: 50%">
          <div v-loading="treeLoadingApp" style="height: 65vh; overflow: auto" class="treeBody">
            <div class="titletext">APP端</div>
            <el-checkbox v-model="checkedApp">全选</el-checkbox>
            <el-tree ref="treeApp" default-expand-all :data="treeDataApp" show-checkbox node-key="menuCode"
              :props="defaultPropsApp">
            </el-tree>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_new = false">取 消</el-button>
        <el-button type="primary" @click="saveTree">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="`分配人员___${chosedRow.roleName}`" :visible.sync="dialog_person" width="520px">
      <div class="treeArea" style="height: 60vh; overflow: auto">
        <el-tree ref="trees" :default-checked-keys="[treeObj.userCode]" @check="treeCheck" :check-on-click-node="true"
          :data="treeDatas" show-checkbox node-key="userCode" :props="defaultProps_new">
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog_person = false">取 消</el-button>
        <el-button type="primary" @click="treeBtn">确 定</el-button>
      </span>
    </el-dialog>
    <alarmBind ref="alarmBindRef" @roleBindAdd = 'getListData'></alarmBind>
  </div>
</template>

<script>
import dialogDrag from "@/utils/drag.js";
import alarmBind from "./dialog/alarmBind.vue";

export default {
  components: {alarmBind},
  directives: {
    dialogDrag,
  },
  data() {
    return {
      chosedRow: {
        roleName: "",
      },
      roleLabelType: "add",
      checkedPc: false,
      checkedApp: false,
      treeLoadingApp: false,
      treeDataApp: [],
      roleCode_now: "",
      treeObj: {},
      defaultProps_new: {
        children: "userInfoList",
        label: "label",
        disabled: "selectable",
      },
      dialog_person: false,
      treeDatas: [],
      treeData: [],
      defaultProps: {
        children: "childrens",
        label: "menuName",
      },
      defaultPropsApp: {
        children: "childrens",
        label: "menuName",
      },
      dialogVisible_new: false,
      roleForm: {
        id: "",
        roleName: "",
        desInfo: "",
      },
      dialogVisible: false,
      height: window.innerHeight - 200, //表格高度
      value: "",
      searchInput: "",
      tableData: [],
      loading: true,
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {
          groupCode: JSON.parse(localStorage.getItem("userInfo")).groupCode,
        },
      },
      userlist: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addUserForm: {
        username: "",
        password: "",
        email: "",
        mobile: "",
      },
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
      selectRoleCode: "",
      roleCode: "",
      treeLoading: false,
      allPCkeys: [],
    };
  },
  created() {
    // this.queryInfo.currPage=this.$route.query.currPage;
    //  this.queryInfo.pageSize=this.$route.query.pageSize;

    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  watch: {
    checkedPc(v) {
      if (v) {
        // let arr=[]
        // this.treeData.forEach(item=>{
        //   arr.push(item.menuCode)
        // })
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.allPCkeys);
        });
      } else {
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys([]);
        });
      }
    },
    checkedApp(v) {
      if (v) {
        let arr = [];
        this.treeDataApp.forEach((item) => {
          arr.push(item.menuCode);
        });
        this.$nextTick(() => {
          this.$refs.treeApp.setCheckedKeys(arr);
        });
      } else {
        this.$nextTick(() => {
          this.$refs.treeApp.setCheckedKeys([]);
        });
      }
    },
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    pcCheck(node, data) {
      console.log(node, data);
    },
    async editRole(row) {
      this.chosedRow.roleName = row.roleName;
      this.roleLabelType = "edit";
      this.roleForm.roleName = row.roleName;
      this.roleForm.desInfo = row.desInfo;
      this.roleForm.id = row.id;
      this.dialogVisible = true;
    },
    async treeBtn(val) {
      console.log("fenpeiry", val);
      let data = {};
      data.groupCode =
        JSON.parse(localStorage.getItem("userInfo")).groupCode || "12345";
      data.roleCode = this.roleCode_now;
      let arrs = this.$refs.trees.getCheckedNodes();
      let arr = [];
      arrs.forEach((item) => {
        arr.push(item.userCode);
      });
      data.userCodeList = arr;
      let res = await this.$http.post("engineeringRole/bingRoleAndUser", data);
      if (res.data.code == 200) {
        this.$notify({
          title: "提示",
          message: "保存成功",
          type: "success",
        });
        this.dialog_person = false;
      } else {
        this.$message.error(res.data.message);
      }
    },
    //负责人
    getInfo(obj) {
      this.roleCode_now = obj.roleCode;
      this.chosedRow.roleName = obj.roleName;
      this.dialog_person = true;
      let data = {
        groupCode: JSON.parse(localStorage.getItem("userInfo")).groupCode,
      };
      this.$http
        .post("engineeringDept/queryDeptAndEmployee", data)
        .then((res) => {
          let arr = res.data.data;
          arr.forEach((item, index) => {
            item.label = item.deptName + " " + item.name;
            // item.userCode=Date.now()+index  //给父节点添加key值，消除控制台报错
            // item.selectable='false'
            // item.userInfoList.forEach(it=>{
            //     it.label=it.name+'('+it.phone+')' + (it.isManager==1? '(负责人)' : '')
            // })
          });
          this.treeDatas = arr;
          this.$http
            .post("engineeringRoleUser/queryList", { roleCode: obj.roleCode })
            .then((res) => {
              let list = [];
              res.data.data.forEach((i) => {
                list.push(i.userCode);
              });
              this.treeDatas.forEach((item) => { });
              setTimeout(() => {
                this.$refs.trees.setCheckedKeys(list);
              });
            });
        });
    },
    openAlarmBind(row) {
      this.$refs.alarmBindRef.init(row)
    },
    treeCheck(data, list) {
      console.log(this.$refs.trees.getCheckedNodes());
    },
    async savePc() {
      let data = {};
      groupCode: JSON.parse(localStorage.getItem("userInfo")).groupCode;
      data.roleCode = this.roleCode;
      let fu = this.$refs.tree.getHalfCheckedKeys();
      let child = this.$refs.tree.getCheckedKeys();
      let arr = fu.concat(child);
      data.menuCodeList = arr;
      this.$http.post("engineeringRole/bingRoleAndMenu", data).then((r) => {
        if (r.data.code == 200) {
          this.$notify({
            title: "提示",
            message: "保存成功",
            type: "success",
          });
          this.dialogVisible_new = false;
        } else {
          this.$message.error(r.data.message);
        }
      });
    },
    async saveApp() {
      let appMenuList = this.$refs.treeApp.getCheckedNodes();
      console.log(appMenuList, "appMenuListappMenuList");
      let roleMenuList = [];
      appMenuList.forEach((item) => {
        roleMenuList.push({ menuCode: item.menuCode, menuName: item.menuName });
      });
      let datas = {};
      datas.roleMenuList = roleMenuList;
      datas.roleCode = this.roleCode;
      this.tableData.forEach((item) => {
        if (item.roleCode == this.roleCode) {
          datas.roleName = item.roleName;
        }
      });
      let res = await this.$http.post(
        "engineeringAppMenuRole/saveBatch",
        datas
      );
      if (res.data.code == 200) {
      } else {
        this.$message.error(res.data.message);
      }
    },
    async saveTree() {
      await this.savePc();
      await this.saveApp();
      this.getAuthInfo(); //重新获取权限信息
    },
    //获取权限信息
    async getAuthInfo() {
      let res = await this.$http.post("engineeringMenu/getMenuAuthList", {
        userCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
      });
      if (res.data.code == 200) {
        localStorage.setItem("authList", JSON.stringify(res.data.data));
      } else {
        this.$message.error(res.data.message);
      }
    },
    choseMenu(obj) {
      this.chosedRow.roleName = obj.roleName;
      this.selectRoleCode = obj.roleCode;
      this.roleCode = obj.roleCode;
      this.getTree();
      this.dialogVisible_new = true;
    },
    getAllPCkeys(arr) {
      arr.forEach((item) => {
        this.allPCkeys.push(item.menuCode);
        if (Array.isArray(item.childrens) && item.childrens.length > 0) {
          this.getAllPCkeys(item.childrens);
        }
      });
    },
    async getTree() {
      //获取节点树 PC端
      this.treeLoading = true;
      this.allPCkeys = [];
      console.log(
        JSON.parse(localStorage.getItem("userInfo")),
        "12222222222222222222222222"
      );
      let res = await this.$http.post("engineeringMenu/queryMenuTree", {
        userCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
      });
      this.treeData = res.data.data;
      this.getAllPCkeys(this.treeData);
      this.getSelect();
      this.getAppMenu();
    },
    async getAppMenu() {
      //获取节点树 APP端
      this.treeLoadingApp = true;
      let res = await this.$http.post(
        "engineeringAppMenu/queryTreeMenuList",
        {}
      );
      console.log(res);
      if (res.data.code == 200) {
        this.getSelectAppMenu();
        let arr = res.data.data;
        this.treeDataApp = arr;
      } else {
        this.$message.error(res.data.message);
      }
    },
    getSelect() {
      //已选节点
      this.$http
        .post("engineeringRoleMenu/queryList", {
          roleCode: this.selectRoleCode,
        })
        .then((res) => {
          if (res.data.code == 200) {
            if (res.data.data != null) {
              setTimeout(() => {
                res.data.data.forEach((item) => {
                  this.$refs.tree.setChecked(item.menuCode, true, false);
                });
                this.treeLoading = false;
              }, 300);
            } else {
              this.$refs.tree.setCheckedKeys([]);
            }
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    async getSelectAppMenu() {
      //获取已选app菜单
      console.log("appsele");
      let res = await this.$http.post("engineeringAppMenuRole/queryList", {
        roleCode: this.selectRoleCode,
      });
      if (res.data.code == 200) {
        if (res.data.data != null) {
          setTimeout(() => {
            this.$refs.treeApp.setCheckedKeys([]);
            res.data.data.forEach((item) => {
              this.$refs.treeApp.setChecked(item.menuCode, true, false);
            });
            this.treeLoadingApp = false;
          }, 300);
        } else {
          this.$refs.treeApp.setCheckedKeys([]);
        }
      } else {
        this.$message.error(res.data.message);
      }
    },
    //条件查询
    search() {
      this.queryInfo.condition.roleName = this.searchInput;
      this.getListData();
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    // 监听排序
    sortChange() { },
    //跳转到详情
    gotoDetail(id) {
      this.roleLabelType = "add";
      this.dialogVisible = true;
      // var  that=this;
      // this.$router.push({
      //   path: "/engineeringRole/details",
      //   query: { id: id,currPage:that.queryInfo.currPage,pageSize:that.queryInfo.pageSize},
      // });
    },
    async submit() {
      if (this.roleForm.roleName.trim() == "") {
        this.$message.error("请输入角色名称");
        return;
      } else {
        let data = JSON.parse(JSON.stringify(this.roleForm));
        console.log("tse", data, JSON.parse(localStorage.getItem("userInfo")));
        if (this.roleLabelType == "add") {
          delete data.id;
        }
        // data.groupCode=JSON.parse(localStorage.getItem('userInfo'))?.groupCode
        // data.groupName=JSON.parse(localStorage.getItem('userInfo'))?.engineeringGroup?.groupName
        data.groupCode = JSON.parse(
          localStorage.getItem("userInfo")
        )?.companyCode;
        data.groupName = JSON.parse(
          localStorage.getItem("userInfo")
        )?.companyName;
        let res = await this.$http.post("engineeringRole/createRole", data);
        if (res.data.code == 200) {
          this.$notify({
            title: "提升",
            message: "保存成功",
            type: "success",
          });
          this.roleForm.roleName = "";
          this.roleForm.desInfo = "";
          this.dialogVisible = false;
          this.getListData();
        } else {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      }
    },
    // 删除用户
    async del(roleCode) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        let data = {};
        data.roleCode = roleCode;
        groupCode: JSON.parse(localStorage.getItem("userInfo")).groupCode;
        that.$http
          .post("engineeringRole/deleteRole", data)
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
            } else {
              that.$message.error(response.data.message);
            }
            that.getListData();
          });
      }
    },
    getListData() {
      // 获取列表
      var that = this;
      that.$http
        .post("/engineeringRole/list", that.queryInfo)
        .then(function (response) {
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 400;
    },
  },
};
</script>

<style lang="scss" scoped>
.bginit {
  ::v-deep .el-input__inner {
    background: #2d5981;
    color: #e7e7e7;
  }

  .top {
    // color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /deep/.el-input-group__append {
      background: #02a7f0;
    }

    /deep/.el-button {
      background: #02a7f0;
      color: #fff;
      border: none;

      &:hover {
        background: #008eff;
      }
    }

    .searchBtn {
      background: #02a7f0;
      border: none;
      color: #fff;

      i {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  .table-class /deep/ .el-table {
    margin-top: 0px;
  }

  .table-class /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid rgba(151, 179, 203, 0.55);
  }

  /deep/.hover-row {
    background-color: #2d5981 !important;
  }

  .table-class /deep/ .el-table--border th {
    background-color: #21527e;

    border: 1px solid #737c85;
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
  }

  .table-class /deep/ .el-table th {
    background-color: #2d5981;
    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
  }

  .table-class /deep/ .el-table td {
    background-color: #2d5981;
    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
  }

  .abc /deep/ .el-table__body-wrapper {
    background: #2d5981;
  }

  /deep/ .el-pagination button:disabled {
    background: rgba(255, 255, 255, 0);
  }

  /deep/ .el-pager li.active {
    color: #f2f2f2;
    background-color: #00b2e6;
    cursor: default;
  }

  /deep/ .el-pagination__total {
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }

  /deep/.el-pager,
  /deep/.btn-prev,
  /deep/.btn-next {
    background: rgba(255, 255, 255, 0);
    color: #fff;

    .number {
      background: rgba(255, 255, 255, 0);
    }
  }

  /deep/ .el-pagination__jump {
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 15px;
  }
}

/deep/ .tableBtn {
  background: rgb(2, 167, 240);
  color: #fff;
  height: 30px;
  border-radius: 6px;
}

.textblue {
  color: #008eff;
}

.managingPeople {
  height: 100%;
  padding: 10px;

  .el-card {
    background-color: #2d5981;
    height: calc(100vh - 105px);
    position: relative;

    /deep/ .el-card__body {
      height: 100%;
    }

    // .el-select>.el-input {
    //   width: 200px;
    // }

    .header-search-box .header-search-input {
      width: 325px;
    }

    input::placeholder {
      font-size: 12px;
    }

    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }

    .footerBox {
      position: absolute;
      width: 100%;
      height: 46px;
      left: 20px;
      right: 20px;
      bottom: 10px;
    }
  }
}

/deep/ .el-scrollbar__view {
  min-width: 100%;
}

/deep/.el-scrollbar__wrap {
  overflow-x: auto;
  height: calc(100%); //多出来的20px是横向滚动条默认的样式
  width: 100%;
}

/deep/ .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
  white-space: nowrap;
  display: inline-block;
}

/deep/ .menuClass {
  .el-dialog__body {
    padding-top: 6px;

    .titletext {
      position: relative;
      padding: 0 0 8px 10px;
    }

    .titletext::before {
      content: "";
      display: block;
      position: absolute;
      left: 0px;
      height: 16px;
      width: 4px;
      background: #008eff;
    }
  }
}

.treeBody::-webkit-scrollbar {
  background: #008eff;
}


.abc ::v-deep .el-table__body tr:hover>td {
  background-color: #223f6c !important;
}
</style>
